/* Help configure the state-base ui.router */
(function () {
  "use strict";

  angular.module("common").run(run);

  run.$inject = ["$trace", "$transitions", "$window", "AuthService"];
  /* @ngInject */
  function run($trace, $transitions, $window, AuthService) {
    $trace.enable("TRANSITION");
    $transitions.onStart({}, function (trans) {
      return AuthService.validateA()
        .then(function () {
          var authInfo = AuthService.OK;
          if (trans.$to().name !== "index.login" && !authInfo) {
            return trans.router.stateService.target("index.login");
          }
          if (trans.$to().name === "index.login" && authInfo) {
            return trans.router.stateService.target("index.counter");
          }
          if (trans.$to().name !== "index.unauthorized" && authInfo) {
            var roles = Meteor.user().roles;
            var allowed = trans.to().allowedTo;
            if (!allowed || _.intersection(allowed, roles).length > 0) {
              // just go to the page
            } else {
              console.log("UNAUTHORIZED FROM permission");
              return trans.router.stateService.target("index.unauthorized");
              //return $q.reject("UNAUTHORIZED FROM permission");
            }
          }
        })
        .catch(function (e) {
          console.log(e); // this errors comes from Angular-meteor....
        });
    });
    $transitions.onFinish({}, function (transition) {
      console.log("Finished Transition from " + transition.from().name + " to " + transition.to().name);
    });
    $transitions.onSuccess({}, function (transition) {
      var element = $window.document.getElementById("main-container");
      if (element) {
        element.focus();
      }
      console.log("Successful Transition from " + transition.from().name + " to " + transition.to().name);
    });

    $transitions.onError({}, function (transition) {
      console.log("Error Transition from " + transition.from().name + " to " + transition.to().name);
    });
    $transitions.onBefore({}, function (transition) {
      console.log("Before Transition from " + transition.from().name + " to " + transition.to().name);
    });
  }
})();
