
(function () {
  'use strict'

  angular
    .module('components.product')
    .controller('ProductsController', ProductsController)
    .directive('mdtCustomCellButton', function () {
      return {
        template: '<md-button class="md-primary md-raised">Angular Material button</md-button>'
      }
    })

  ProductsController.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$reactive',
    '$mdDialog',
    'ProductService',
    'ManufacturerService',
    'ToastService'
  ]
  /* @ngInject */
  function ProductsController (
    $scope,
    $rootScope,
    $timeout,
    $reactive,
    $mdDialog,
    ProductService,
    ManufacturerService,
    ToastService
    ) {
    var _this = this
    this.dataReady = false
    this.displayOnlyDisabled = false

    this.$onInit = function () {
      getProducts(this.displayOnlyDisabled)

      this.labelSearchChanged = function () {
        getProducts(this.displayOnlyDisabled, this.labelSearch)
      }

      this.selectProduct = function (productId) {
        $rootScope.$broadcast('productPicker::complete', { productId: productId })
      }

      this.hideModal = function () {
        $mdDialog.hide()
      }

      this.disableProduct = function (productId) {
        ProductService.disableProduct(productId)
        .then(function (result) {
          ToastService.displayToast('Produit supprimé')
        })
        .catch(function (error) {
          ToastService.displayToast("une erreur s'est produite pendant la suppression", error)
        })
      }

      this.removeProductFromTable = function (product) {
        this.products.splice(_this.products.indexOf(product), 1)
      }

      this.restoreProduct = function (productId) {
        ProductService.restoreProduct(productId)
        .then(function (result) {
          ToastService.displayToast('Produit restauré')
        })
        .catch(function (error) {
          ToastService.displayToast("une erreur s'est produite pendant la restauration", error)
        })
      }

      this.toggleDisabledProducts = function () {
        this.dataReady = false
        getProducts(!_this.displayOnlyDisabled, this.labelSearch)
        this.displayOnlyDisabled = !_this.displayOnlyDisabled
      }
    }

    function getProducts (isDisabled, label) {
      _this.dataReady = false
      ProductService.getProducts(isDisabled, label)
      .then(function (result) {
        _this.dataReady = true
        _this.products = result
      })
    }
  }
})()
