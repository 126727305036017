
(function () {
  'use strict'

  angular
    .module('components.order')
    .controller('OrdersController', OrdersController)

  OrdersController.$inject = [
    '$q',
    '$filter',
    '$scope',
    '$timeout',
    '$reactive',
    'OrderService',
    'AccountService',
    'PDFService',
    'moment'
  ]
  /* @ngInject */
  function OrdersController (
    $q,
    $filter,
    $scope,
    $timeout,
    $reactive,
    OrderService,
    AccountService,
    PDFService,
    moment
    ) {
    var _this = this

    this.queryParams = {
      startDate: moment().hour(0).minute(0).second(0).subtract(7, 'days').toDate(),
      endDate: moment().hour(23).minute(59).second(59).toDate()
    }

    this.$onInit = function () {
      this.getOrders(this.queryParams)
    }

    this.getOrders = function (queryParams) {
      OrderService.getOrders(queryParams)
      .then(function (result) {
        _this.orders = result
      })
    }

    this.onChangeDate = function (position) {
      _this.getOrders(this.queryParams)
    }

    this.displayPDF = function (order) {
      PDFService.displayPDF(order)
    }

    this.getCsvHeaders = function () {
      var headers = [
        'NUMERO',
        'DATE',
        'TOTAL HT',
        'TOTAL TVA',
        'TOTAL TTC',
        'NB PRODUITS',
        'VENDEUR',
        'RENDU',
        'TYPE DE PAIEMENT 1',
        'MONTANT 1',
        'TYPE DE PAIEMENT 2',
        'MONTANT 2'

      ]
      return headers
    }

    this.createExportData = function () {
      return $q(function (resolve, reject) {
        console.log(_this.orders)
        var data = []
        for (var i = 0; i < _this.orders.length; i++) {
          // if (!_this.orders[i].payment) continue
          var n = {}
          n.orderInvoiceNumber = _this.orders[i].invoiceNumber
          n.orderDate = $filter('date')(_this.orders[i].createdAt, 'short')
          n.orderTotalTicketSumExcVTA = toFixed(_this.orders[i].total.ticketSumExcVTA, 2)
          n.orderTotalTicketSumVTA = toFixed(_this.orders[i].total.ticketSumVTA, 2)
          n.orderTotalTicketSumIncVTA = toFixed(_this.orders[i].total.ticketSumIncVTA, 2)
          n.orderTotalTicketProductCount = _this.orders[i].total.productCount
          n.orderSeller = _this.orders[i].user.lastName + ' ' + _this.orders[i].user.firstName
          n.orderPaymentChange = _this.orders[i].payment.change ? toFixed(_this.orders[i].payment.change, 2) : ''
          n.orderPaymentMean0 = _this.orders[i].payment.means[0] ? _this.orders[i].payment.means[0].paymentType.label : ''
          n.orderPaymentValue0 = _this.orders[i].payment.means[0] ? toFixed(_this.orders[i].payment.means[0].value, 2) : ''
          n.orderPaymentMean1 = _this.orders[i].payment.means[1] ? _this.orders[i].payment.means[1].paymentType.label : ''
          n.orderPaymentValue1 = _this.orders[i].payment.means[1] ? toFixed(_this.orders[i].payment.means[1].value, 2) : ''
          data.push(n)
        }

        resolve(data)
      })
    }

    function toFixed (num, precision) {
      return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision)
    }
  }
})()
