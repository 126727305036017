(function () {
  'use strict'
  angular.module('app', [
    'core',
    'templates',
    'layout',
    'components',
    'common',
    'utils'
  ])
})()
