var clients = {
  templateUrl: 'app/components/client/clients/clients.html',
  controller: 'ClientsController',
  bindings: {
    pickerMode: '<',
  },
};
angular.module('components.client')
  .component('clients', clients);

