(function () {
  "use strict";

  var core = angular.module("core");
  /**
   * Translation
   * @description: the angular-translate directive configuration
   */
  // core.config(translateConfig);
  // translateConfig.$inject = ['$translateProvider', '$translatePartialLoaderProvider'];
  // /* @ngInject */
  // function translateConfig($translateProvider, $translatePartialLoaderProvider) {
  //   $translateProvider.useStaticFilesLoader({
  //     prefix: '/app/locales/locale-',
  //     suffix: '.json',
  //   });
  //   $translatePartialLoaderProvider.addPart('layout');
  //   $translateProvider.useLoader('$translatePartialLoader', {
  //     urlTemplate: '/app/{part}/locales/locale-{lang}.json',
  //   });
  //   $translateProvider.preferredLanguage('fr');
  //   $translateProvider.useSanitizeValueStrategy('escape'); // Enable escaping of HTML
  // }

  /**
   * MdDatePicker
   * @description: Localization and config for md date picker
   */
  core.config(mdDateConfig);
  mdDateConfig.$inject = ["$mdDateLocaleProvider"];
  /* @ngInject */
  function mdDateConfig($mdDateLocaleProvider) {
    $mdDateLocaleProvider.formatDate = function (date) {
      return moment(date).format("DD/MM/YYYY");
    };
  }

  /**
   * Barcode Scanner
   * @initialize the barcode scanner listener
   */
  core.run(barcodeScannerConfig);
  barcodeScannerConfig.$inject = ["BarcodeScannerService"];
  /* @ngInject */
  function barcodeScannerConfig(BarcodeScannerService) {
    BarcodeScannerService.initialize();
  }
})();
