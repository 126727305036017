
/**
 *
 * @ngdoc module
 * @name components
 *
 * @requires components.contact
 * @requires components.auth
 *
 * @description
 *
 * This is the components module. It includes all of our components.
 *
 **/

angular
  .module('components', [
    'components.auth',
    'components.account',
    'components.product',
    'components.counter',
    'components.barcode',
    'components.order',
    'components.client',
    'components.manufacturer',
    'components.stock',
    'components.payment',
  ]);
