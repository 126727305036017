(function () {
  'use strict';

  angular
    .module('components.payment')
    .controller('PaymentMeanController', PaymentMeanController);

  PaymentMeanController.$inject = [
    '$scope',
    '$stateParams',
  ];
  /* @ngInject */
  function PaymentMeanController(
    $scope,
    $stateParams
    ) {

    var _this = this;

    this.$onInit = function () {
      // getting payment types
      this.paymentTypes = [
        { id: 1, label: 'Carte bancaire', type: 'CREDIT_CARD' },
        { id: 2, label: 'Chèque', type: 'CHECK' },
        { id: 3, label: 'Espèces', type: 'CASH' },
      ];
    };

    this.updatePayment = function () {
      // if card avoid exceed ticket Max
      if ( (_this.payment.paymentType.type === 'CREDIT_CARD' || _this.payment.paymentType.type === 'CHECK') && (_this.payment.value > _this.ticket.total.ticketSumIncVTA)) {
        _this.payment.value = _this.ticket.total.ticketSumIncVTA;
      }


      $scope.$emit('payment::update');
    };

    this.deletePayment = function (payment) {
      $scope.$emit('payment::delete', payment);
    };

    function toFixed(num, precision) {
      return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    }

  }
})();
