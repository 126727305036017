(function () {
  'use strict'

  angular
    .module('components.product')
    .controller('ProductDetailController', ProductDetailController)

  ProductDetailController.$inject = [
    'ProductService',
    '$scope',
    '$stateParams',
    '$reactive',
    '$mdDialog',
    'ManufacturerService',
    'ToastService'
  ]
  /* @ngInject */
  function ProductDetailController (
    ProductService,
    $scope,
    $stateParams,
    $reactive,
    $mdDialog,
    ManufacturerService,
    ToastService
    ) {
    var _this = this
    this.manufacturers = null
    this.product = null

    this.$onInit = function () {
      ManufacturerService.getManufacturers()
      .then(function (result) {
        _this.manufacturers = result
      })

      ProductService.getOneProductById($stateParams.id)
      .then(function (result) {
        _this.product = result
      })
    }

    this.changeVTA = function (e) {
      this.product.sellingPriceIncVTA =
        parseFloat(toFixed(this.product.sellingPriceExcVTA + (this.product.sellingPriceExcVTA * (this.product.VTA / 100)), 2))
      this.product.buyingPriceIncVTA =
        parseFloat(toFixed(this.product.buyingPriceExcVTA + (this.product.buyingPriceExcVTA * (this.product.VTA / 100)), 2))
    }

    this.changeExcVTA = function (e) {
      if (e === 'sellingPrice') {
        this.product.sellingPriceIncVTA =
          parseFloat(toFixed(this.product.sellingPriceExcVTA + (this.product.sellingPriceExcVTA * (this.product.VTA / 100)), 2))
      } else if (e === 'buyingPrice') {
        this.product.buyingPriceIncVTA =
          parseFloat(toFixed(this.product.buyingPriceExcVTA + (this.product.buyingPriceExcVTA * (this.product.VTA / 100)), 2))
      } else {
        console.log('nor sellingPrice or buying price given')
      }
    }

    this.changeIncVTA = function (e) {
      if (e === 'sellingPrice') {
        this.product.sellingPriceExcVTA = parseFloat(toFixed(this.product.sellingPriceIncVTA * (100 / (100 + this.product.VTA)), 2))
      } else if (e === 'buyingPrice') {
        this.product.buyingPriceExcVTA = parseFloat(toFixed(this.product.buyingPriceIncVTA * (100 / (100 + this.product.VTA)), 2))
      } else {
        console.log('nor sellingPrice or buying price given')
      }
    }

    this.updateProduct = function (productForm, productData) {
      ProductService.getOneByBarcode(productData.barcode)
      .then(function (result) {
        if (!result || result._id === productData._id || result.disabled === 1) {
          ProductService.updateProduct(productForm, productData)
          .then(function (result) {
            ToastService.displayToast('Produit mis à jour avec succès')
          })
        } else {
          console.log(result)
          ToastService.displayToast('Code barre déja utilisé ( ' + result.label + ' )')
        }
      })
    }

    function toFixed (num, precision) {
      return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision)
    }
  }
})()
