(function () {
  "use strict";

  angular.module("components.client").run(appRun);

  appRun.$inject = ["routerHelper", "AuthService"];
  /* @ngInject */
  function appRun(routerHelper, AuthService) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
      {
        state: "index.clients",
        config: {
          url: "clients",
          allowedTo: ["super-admin"],
          views: {
            "main@index": {
              template: "<clients></clients>",
            },
          },
          title: "_account.clients",
          ncyBreadcrumb: {
            label: "",
          },
          settings: {
            nav: 1,
          },
        },
      },
    ];
  }
})();
