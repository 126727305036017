
(function () {
  'use strict';

  angular
    .module('components.manufacturer')
    .controller('ManufacturersController', ManufacturersController);

  ManufacturersController.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$reactive',
    'ManufacturerService',
  ];
  /* @ngInject */
  function ManufacturersController(
    $scope,
    $rootScope,
    $timeout,
    $reactive,
    ManufacturerService
    ) {

    this.$onInit = function () {
      this.nameSearch = '';
      this.nameSearchChanged = function () {
        console.log('changed', this.nameSearch);
      };

      $reactive(this).attach($scope);
      var sub = this.subscribe('manufacturers', function () {
        return [this.getReactively('nameSearch')];
      });

      this.helpers({
        manufacturers: function () {
          return Manufacturers.find({});
        },
      });

    };

  }
})();
