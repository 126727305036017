// angular.module('components.product')
//   .config(mdDialogProductsConfig)
//   .controller('ProductsPickerController', ProductsPickerController);

// ProductsPickerController.$inject = [
//   '$scope',
//   '$mdDialog',
// ];
// /* @ngInject */
// function ProductsPickerController($scope, $mdDialog) {
//   $scope.cancel = function () {
//     $mdDialog.cancel();
//   };
// }

// mdDialogProductsConfig.$inject = ['$mdDialogProvider'];
// /* @ngInject */
// function mdDialogProductsConfig($mdDialogProvider) {
//   $mdDialogProvider.addPreset('productsPicker', {
//     options: function () {
//       return {
//         clickOutsideToClose: true,
//         controller: 'ProductsPickerController',
//         template: '<md-dialog aria-label="List dialog">' +
//         '<md-dialog-content>' +
//         '<products picker-mode="true"></products>' +
//         '</md-dialog-content>' +
//         '<md-dialog-actions>' +
//         '  <md-button ng-click="cancel()" class="md-primary">' +
//         '    Close Dialog' +
//         '  </md-button>' +
//         '</md-dialog-actions>' +
//         '</md-dialog>',
//       };
//     },
//   });
// }
