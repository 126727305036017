(function () {
  "use strict";

  angular.module("components.counter").factory("TicketService", TicketService);

  /**
   * Data Service
   * @type {Array}
   */
  TicketService.$inject = ["$q", "$state", "$http"];
  /* @ngInject */
  function TicketService($q, $state, $http) {
    var ticket = initTicket();

    var service = {
      getTicket: getTicket,
      getTotal: getTotal,
      getClient: getClient,
      addProduct: addProduct,
      updateTotal: updateTotal,
      removeProduct: removeProduct,
      addClient: addClient,
      resetTicket: resetTicket,
      resetClient: resetClient,
      leftToPay: leftToPay,
      printTicket: printTicket,
    };

    return service;

    function initTicket() {
      var newTicket = {
        products: [],
        client: {},
        total: {
          ticketSumIncVTA: 0,
          ticketSumExcVTA: 0,
          ticketSumVTA: 0,
          productCount: 0,
        },
        payment: {
          means: [],
          change: 0,
          leftToPay: 0,
        },
      };
      return newTicket;
    }

    function resetTicket() {
      ticket = initTicket();
    }

    function resetClient() {
      ticket.client = {};
    }

    function addProduct(product) {
      var productCopy = angular.copy(product);
      var productPosition = isInTicket(productCopy);

      if (productPosition === -1) {
        productCopy.quantity = 1;
        ticket.products.push(productCopy);
      } else {
        ticket.products[productPosition].quantity++;
      }

      updateTotal();
      leftToPay();
    }

    function addClient(client) {
      var clientCopy = angular.copy(client);
      angular.extend(ticket.client, clientCopy);
    }

    function removeProduct(product) {
      console.log("removeProduct");
      for (var i = 0; i < ticket.products.length; i++) {
        if (ticket.products[i]._id === product._id) {
          ticket.products.splice(i, 1);
          updateTotal();
          leftToPay();
          break;
        }
      }
    }

    function updateTotal() {
      ticket.total.ticketSumExcVTA = 0;
      ticket.total.ticketSumIncVTA = 0;
      ticket.total.ticketSumVTA = 0;
      ticket.total.productCount = 0;

      for (var i = 0; i < ticket.products.length; i++) {
        ticket.total.ticketSumExcVTA += parseFloat(
          toFixed(ticket.products[i].sellingPriceExcVTA * ticket.products[i].quantity, 2)
        );
        ticket.total.ticketSumIncVTA += parseFloat(
          toFixed(ticket.products[i].sellingPriceIncVTA * ticket.products[i].quantity, 2)
        );
        ticket.total.productCount += ticket.products[i].quantity;
      }

      ticket.total.ticketSumVTA = ticket.total.ticketSumIncVTA - ticket.total.ticketSumExcVTA;
    }

    function getTicket() {
      return ticket;
    }

    function getTotal() {
      return ticket.total;
    }

    function getClient() {
      return ticket.client;
    }

    /**
     * isInTicket
     * @note: in case element are created through a script remember that meteor doesn't use the ObjectId method of mongo
     */
    function isInTicket(product) {
      var alreadyInTicket = -1;
      if (ticket.products.length > 0) {
        for (var i = 0; i < ticket.products.length; i++) {
          if (ticket.products[i]._id === product._id) {
            alreadyInTicket = i;
            break;
          }
        }
      }

      return alreadyInTicket;
    }

    function leftToPay() {
      if (ticket.products.length < 1) {
        console.log("no products nothing to process, reinit payments");
        ticket.payment.means.length = 0;
        ticket.payment.change = 0;
        ticket.payment.leftToPay = 0;
        return;
      }

      ticket.payment.leftToPay = ticket.total.ticketSumIncVTA;
      console.log("process left to pay", ticket.payment);

      var totalPay = 0;
      for (var i = 0; i < ticket.payment.means.length; i++) {
        totalPay += ticket.payment.means[i].value;
      }

      if (ticket.total.ticketSumIncVTA - totalPay >= 0) {
        ticket.payment.leftToPay = ticket.total.ticketSumIncVTA - totalPay;
        ticket.payment.change = 0;
      } else {
        ticket.payment.leftToPay = 0;
        ticket.payment.change = Math.abs(ticket.total.ticketSumIncVTA - totalPay);
      }
    }

    function printTicket(ticket) {
      var printerServer = "http://192.168.1.42:44444"; // desjoyaux server, move it in a config file
      $http
        .post(printerServer + "/print/ticket", ticket, { headers: { "Content-Type": "application/json" } })
        .then(function (response) {
          //return response;
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    function toFixed(num, precision) {
      return (+(Math.round(+(num + "e" + precision)) + "e" + -precision)).toFixed(precision);
    }
  }
})();
