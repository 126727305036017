(function () {
  'use strict'

  angular
  .module('components.product')
  .factory('ProductService', ProductService)

  /**
  * Data Service
  * @type {Array}
  */
  ProductService.$inject = [
    '$q',
    '$state'
  ]
  /* @ngInject */
  function ProductService ($q, $state) {
    var service = {
      createProduct: createProduct,
      updateProduct: updateProduct,
      getOneByBarcode: getOneByBarcode,
      getOneProductById: getOneProductById,
      getProducts: getProducts,
      disableProduct: disableProduct,
      restoreProduct: restoreProduct,
      getBestSellers: getBestSellers
    }

    return service

    function createProduct (productForm, productData) {
      return $q(function (resolve, reject) {
        console.log('saving product:', productData)
        Meteor.call('createProduct', productData, function (error, result) {
          console.log(error, result)
          !error ? resolve(result) : reject(error)
        })
      })
    }

    function updateProduct (productForm, productData) {
      console.log(productData)
      return $q(function (resolve, reject) {
        Meteor.call('updateProduct', productData, function (error, result) {
          console.log(error, result)
          error ? reject('product update error') : resolve(result)
        })
      })
    }

    function disableProduct (productId) {
      return $q(function (resolve, reject) {
        console.log('Disabling product', productId)
        Meteor.call('disableProduct', productId, function (error, result) {
          !error ? resolve(result) : reject(error)
        })
      })
    }

    function restoreProduct (productId) {
      return $q(function (resolve, reject) {
        console.log('restoring product', productId)
        Meteor.call('restoreProduct', productId, function (error, result) {
          !error ? resolve(result) : reject(error)
        })
      })
    }

    function getProducts (disabled, label) {
      return $q(function (resolve, reject) {
        console.log('getting products')
        console.log('label' + label)
        Meteor.call('getProducts', disabled, label, function (error, result) {
          !error ? resolve(result) : reject(error)
        })
      })
    }

    function getBestSellers () {
      return $q(function (resolve, reject) {
        console.log('getting getBestSellers')
        Meteor.call('getBestSellers', function (error, result) {
          !error ? resolve(result) : reject(error)
        })
      })
    }

    function getOneByBarcode (barcode) {
      return $q(function (resolve, reject) {
        Meteor.call('getOneProductByBarcode', parseInt(barcode, 10), function (error, result) {
          console.log(error, result)
          error ? reject('getOneProductByBarcode error') : resolve(result)
        })
      })
    }

    function getOneProductById (productId) {
      return $q(function (resolve, reject) {
        console.log(productId)
        Meteor.call('getOneProductById', productId, function (error, result) {
          console.log(error, result)
          error ? reject('getOneProductById error') : resolve(result)
        })
      })
    }
  }
})()
