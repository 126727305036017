(function () {
  'use strict';

  angular
    .module('components.product')
    .controller('ProductCreateController', ProductCreateController);

  ProductCreateController.$inject = [
    '$scope',
    'ProductService',
    'ManufacturerService',
    'ToastService',
  ];
  /* @ngInject */
  function ProductCreateController(
    $scope,
    ProductService,
    ManufacturerService,
    ToastService
    ) {
    var _this = this;
    this.manufacturers;

    this.$onInit = function () {
      this.product = {};
      this.product.VTA = 20;
    };

    ManufacturerService.getManufacturers().then(function (result) {
      _this.manufacturers = result;
    });

    this.changeVTA = function (e) {
      this.product.sellingPriceIncVTA = parseFloat(toFixed(this.product.sellingPriceExcVTA + (this.product.sellingPriceExcVTA * (this.product.VTA / 100)), 2));
      this.product.buyingPriceIncVTA = parseFloat(toFixed(this.product.buyingPriceExcVTA + (this.product.buyingPriceExcVTA * (this.product.VTA / 100)), 2));
    };

    this.changeExcVTA = function (e) {
      if (e === 'sellingPrice') {
        this.product.sellingPriceIncVTA = parseFloat(toFixed(this.product.sellingPriceExcVTA + (this.product.sellingPriceExcVTA * (this.product.VTA / 100)), 2));
      } else if (e === 'buyingPrice') {
        this.product.buyingPriceIncVTA = parseFloat(toFixed(this.product.buyingPriceExcVTA + (this.product.buyingPriceExcVTA * (this.product.VTA / 100)), 2));
      } else {
        console.log('nor sellingPrice or buying price given');
      }
    };

    this.changeIncVTA = function (e) {
      if (e === 'sellingPrice') {
        this.product.sellingPriceExcVTA = parseFloat(toFixed(this.product.sellingPriceIncVTA * (100 / (100 + this.product.VTA)), 2));
      } else if (e === 'buyingPrice') {
        this.product.buyingPriceExcVTA = parseFloat(toFixed(this.product.buyingPriceIncVTA * (100 / (100 + this.product.VTA)), 2));
      } else {
        console.log('nor sellingPrice or buying price given');
      }
    };

    this.createProduct = function (productForm, productData) {
      ProductService.createProduct(productForm, productData)
      .then(function (result) {
        _this.product = {};
        _this.product.VTA = 20;
        ToastService.displayToast('Produit crée avec succès');
      });
    };

    function toFixed(num, precision) {
      return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    }
  }
})();
