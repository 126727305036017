(function () {
  'use strict';

  angular
  .module('components.auth')
  .run(appRun);

  appRun.$inject = ['routerHelper', 'AuthService'];
  /* @ngInject */
  function appRun(routerHelper, AuthService) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
    {
      state: 'index.login',
      config: {
        url: 'login',
        views: {
          'main@index': {
            template: '<login></login>',
          },
        },
        title: '_account.login',
        ncyBreadcrumb: {
          label: '',
        },
        settings: {
          nav: 1,
        },
      },
    },
    ];
  }
})();
