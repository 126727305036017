(function () {
  'use strict'

  angular
    .module('components.counter')
    .controller('CounterController', CounterController)

  CounterController.$inject = [
    '$scope',
    'ProductService',
    'TicketService',
    'ClientService',
    'OrderService',
    '$mdDialog',
    'ToastService'
  ]
  /* @ngInject */
  function CounterController (
    $scope,
    ProductService,
    TicketService,
    ClientService,
    OrderService,
    $mdDialog,
    ToastService
  ) {
    var _this = this

    this.initTicket = function () {
      _this.ticket = TicketService.getTicket()
      _this.total = TicketService.getTotal()
      _this.client = TicketService.getClient()
    }

    this.$onInit = function () {
      this.initTicket()

      ProductService.getBestSellers()
      .then(function (result) {
        _this.bestSellers = result
      })
    }

    $scope.$on('barcodeScanner::complete', function (event, args) {
      ProductService.getOneByBarcode(args.barcode)
      .then(function (result) {
        if (result) {
          TicketService.addProduct(result)
        } else {
          console.log('no result for barcode ', args.barcode)
        }
      })
    })

    $scope.$on('productPicker::complete', function (event, args) {
      addProductToTicket(args.productId)
    })

    $scope.$on('clientPicker::complete', function (event, args) {
      ClientService.getOneClientById(args.clientId)
      .then(function (result) {
        if (result) {
          TicketService.addClient(result)
        }
      })
    })

    this.createOrder = function (ticket) {
      OrderService.createOrder(ticket).then(function (result) {
        TicketService.printTicket(result)
        ToastService.displayToast('Vente crée avec succès')
        TicketService.resetTicket()
        _this.initTicket()
      })
    }

    this.openProductsPicker = function (ev) {
      $mdDialog.show($mdDialog.productsPicker())
    }

    this.openClientsPicker = function (ev) {
      $mdDialog.show($mdDialog.clientsPicker())
    }

    this.resetTicket = function () {
      TicketService.resetTicket()
      _this.initTicket()
    }

    this.resetClient = function () {
      TicketService.resetClient()
      _this.client = TicketService.getClient()
    }

    this.testToast = function () {
      ToastService.displayToast()
    }

    this.isClientEmpty = function () {
      return Object.keys(_this.client).length === 0
    }

    this.addProductToTicket = addProductToTicket

    function addProductToTicket (productId) {
      ProductService.getOneProductById(productId)
      .then(function (result) {
        if (result) {
          TicketService.addProduct(result)
        }
      })
    }
  }
})()
