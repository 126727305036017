(function () {
  'use strict';

  angular
      .module('core', [
         'angular-meteor',
         'angular-meteor.auth',
         'ui.router',
         'pascalprecht.translate',
         'ngMaterial',
         'ngLocale',
         'components.barcode',
         'ngSanitize',
         'ngCsv',
         'angularMoment',
      ]);
})();
