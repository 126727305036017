(function () {
  "use strict";

  angular.module("common").provider("routerHelper", routerHelperProvider);

  routerHelperProvider.$inject = ["$locationProvider", "$stateProvider", "$urlRouterProvider"];
  /* @ngInject */
  function routerHelperProvider($locationProvider, $stateProvider, $urlRouterProvider) {
    var config = {
      resolveAlways: {},
    };

    $locationProvider.html5Mode(true);

    $urlRouterProvider.when("", "/");
    $urlRouterProvider.when("/", "/counter");

    this.$get = RouterHelper;
    RouterHelper.$inject = ["$state"];
    /* @ngInject */
    function RouterHelper($state) {
      var service = {
        configureStates: configureStates,
        getStates: getStates,
      };

      return service;

      function configureStates(states, otherwisePath) {
        states.forEach(function (state) {
          angular.extend(state.config.resolve || {}, config.resolveAlways);
          $stateProvider.state(state.state, state.config);
        });
      }

      function getStates() {
        return $state.get();
      }
    }
  }
})();
