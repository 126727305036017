(function () {
  'use strict';

  angular
    .module('components.counter')
    .controller('CounterOrderController', CounterOrderController);

  CounterOrderController.$inject = [
    '$scope',
    'ProductService',
    'TicketService',
  ];
  /* @ngInject */
  function CounterOrderController(
   $scope,
   ProductService,
   TicketService
  ) {
    var _this = this;

    this.updateQuantity = function (item) {
      if (item.quantity === 0) {
        console.log('removing', item);
        TicketService.removeProduct(item);
      } else if (!item.quantity) {
        item.quantity = 1;
      } else {
        document.activeElement.blur();
        TicketService.updateTotal();
        TicketService.leftToPay();
      }
    };

    this.removeProduct = function (item) {
      TicketService.removeProduct(item);

    };

  }
})();
