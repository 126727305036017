
(function () {
  'use strict'

  angular
    .module('components.client')
    .controller('ClientsController', ClientsController)

  ClientsController.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$reactive',
    '$mdDialog',
    'ClientService',
    'ToastService'
  ]
  /* @ngInject */
  function ClientsController (
    $scope,
    $rootScope,
    $timeout,
    $reactive,
    $mdDialog,
    ClientService,
    ToastService
    ) {
    var _this = this
    this.dataReady = false
    this.displayOnlyDisabled = false

    this.$onInit = function () {
      this.fullNameSearchChanged = function () {
        getClients(this.displayOnlyDisabled, this.fullNameSearch)
      }
      getClients(this.displayOnlyDisabled)

      this.selectClient = function (clientId) {
        $rootScope.$broadcast('clientPicker::complete', { clientId: clientId })
        $mdDialog.hide()
      }

      this.hideModal = function () {
        $mdDialog.hide()
      }

      this.disableClient = function (clientId) {
        ClientService.disableClient(clientId)
        .then(function (result) {
          ToastService.displayToast('Client supprimé')
        })
        .catch(function (error) {
          ToastService.displayToast("une erreur s'est produite pendant la suppression", error)
        })
      }

      this.removeClientFromTable = function (client) {
        this.clients.splice(_this.clients.indexOf(client), 1)
      }

      this.restoreClient = function (clientId) {
        ClientService.restoreClient(clientId)
        .then(function (result) {
          ToastService.displayToast('Produit restauré')
        })
        .catch(function (error) {
          ToastService.displayToast("une erreur s'est produite pendant la restauration", error)
        })
      }

      this.toggleDisabledClients = function () {
        this.dataReady = false
        getClients(!_this.displayOnlyDisabled, this.fullNameSearch)
        this.displayOnlyDisabled = !this.displayOnlyDisabled
      }
    }

    function getClients (isDisabled, fullName) {
      _this.dataReady = false
      ClientService.getClients(isDisabled, fullName)
      .then(function (result) {
        _this.dataReady = true
        _this.clients = result
      })
    }
  }
})()
