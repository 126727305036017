(function () {
  'use strict';

  angular
  .module('components.account')
  .factory('AccountService', AccountService);

  /**
  * Data Service
  * @type {Array}
  */
  AccountService.$inject = [
  '$q',
  '$state',
  ];
  /* @ngInject */
  function AccountService($q, $state) {

    var service = {
      createUser: createUser,
      updateUser: updateUser,
      updatePassword: updatePassword,

    };

    return service;

    /**
    * Create user
    * Create it server side (We don't want autologin since there no creation from unlogged users)
    */
    function createUser(userForm, userData) {

      return $q(function (resolve, reject) {
        Meteor.call('createNewUser', userData.username, userData.password, userData.roles, userData.email, function (error, result) {
          // !error ? resolve(result) : reject(error);
          if(!error) {
            console.log(userData);
            console.log(result);
            resolve(result)
          } else {
            reject(error)
          }
        });
      });
    };

    /**
    * Update user
    */
    function updateUser(userData) {

      return $q(function (resolve, reject) {
        updateUsername(userData)
        .then(updateEmail)
        .then(updateAdditionnalUserData)
        .then(function () {
          resolve();
        })
        .catch(function (error) {
          return reject(error);
        });
      });
    };

    function updateUsername(userData) {
      return $q(function (resolve, reject) {
        Meteor.call('updateAccount', userData._id, userData.username, function (error, result) {
          !error ? resolve(userData) : reject(error);
        });
      });
    }

    function updateEmail(userData) {
      return $q(function (resolve, reject) {
        console.log('userdata', userData);
        Meteor.call('updateEmail', userData._id, userData.emails[0].address, function (error, result) {
          !error ? resolve(userData) : reject(error);
        });

      });
    }

    function updatePassword(password) {
      return $q(function (resolve, reject) {
        Meteor.call('updatePassword', password, function (error) {
          !error ? resolve(password) : reject(error);
        });
      });
    }

    function updateAdditionnalUserData(userData) {
      return $q(function (resolve, reject) {
        Meteor.users.update(
          { _id: userData._id }, {
            $set: {
              lastName: userData.lastName,
              firstName: userData.firstName,
              roles: userData.roles,
            },
          },
          function (error, affectedDocuments) {
            !error ? resolve(affectedDocuments) : reject(error);
          });
      });
    }
  }
})();
