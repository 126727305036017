(function () {
  "use strict";

  angular.module("components.product").run(appRun);

  appRun.$inject = ["routerHelper", "AuthService"];
  /* @ngInject */
  function appRun(routerHelper, AuthService) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
      {
        state: "index.product-detail",
        config: {
          url: "product/:id",
          views: {
            "main@index": {
              template: "<product-detail></product-detail>",
            },
          },
          title: "_product.account.detail",
          ncyBreadcrumb: {
            label: "",
          },
          settings: {
            nav: 1,
          },
        },
      },
    ];
  }
})();
