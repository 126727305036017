(function () {
  "use strict";
  angular.module("components.auth").directive("allowedTo", [
    "ngIfDirective",
    function (ngIfDirective) {
      var ngIf = ngIfDirective[0];

      return {
        transclude: ngIf.transclude,
        priority: ngIf.priority - 1,
        terminal: ngIf.terminal,
        restrict: ngIf.restrict,
        link: function (scope, element, attributes) {
          // find the initial ng-if attribute and init ifEvaluator
          var initialNgIf = attributes.ngIf;
          var ifEvaluator;
          var permissions = [];
          var permissionData = scope.$eval(attributes.allowedTo);
          if (typeof permissionData === "string") {
            permissions.push(permissionData);
          } else {
            permissions = permissionData;
          }

          // if it exists, evaluates ngIf && ifAuthenticated
          if (initialNgIf) {
            ifEvaluator = function () {
              if (scope.$eval(initialNgIf)) {
                var userRoles = Meteor.user().roles;
                if (_.intersection(permissions, userRoles).length > 0) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            };
          } else {
            // if there's no ng-if, process normally
            ifEvaluator = function () {
              if (!Meteor.user()) {
                return false;
              } else {
                var userRoles = Meteor.user().roles;
                if (_.intersection(permissions, userRoles).length > 0) {
                  return true;
                } else {
                  return false;
                }
              }
            };
          }

          attributes.ngIf = ifEvaluator;
          ngIf.link.apply(ngIf, arguments);
        },
      };
    },
  ]);
})();
