(function () {
  "use strict";

  angular.module("core").run(appRun);
  appRun.$inject = ["routerHelper"];
  /* @ngInject */
  function appRun(routerHelper) {
    var otherwise = "404";
    routerHelper.configureStates(getStates(), otherwise);
  }

  function getStates() {
    return [
      {
        state: "index.404",
        config: {
          url: "404",
          views: {
            "main@index": {
              templateUrl: "app/common/core/templates/404.html",
              controller: angular.noop(),
            },
          },

          title: "404",
        },
      },
      {
        state: "index.unauthorized",
        config: {
          url: "unauthorized",
          views: {
            "main@index": {
              templateUrl: "app/common/core/templates/unauthorized.html",
              controller: angular.noop(),
            },
          },

          title: "unauthorized",
        },
      },
    ];
  }
})();
