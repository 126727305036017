(function () {
  'use strict';

  angular
  .module('components.payment')
  .factory('PaymentService', PaymentService);

  /**
  * Data Service
  * @type {Array}
  */
  PaymentService.$inject = [
  '$q',
  '$state',
  ];
  /* @ngInject */
  function PaymentService($q, $state) {

    var service = {
      getPaymentMeans: getPaymentMeans,
    };

    return service;

    function getPaymentMeans() {
      return $q(function (resolve, reject) {
        var paymentMeans = [
        { label: 'carte Bancaire' },
        { label: 'Cheque' },
        { label: 'Especes' },
        ];
        resolve(paymentMeans);
      });
    }
  }
})();
