(function () {
  'use strict';

  angular
  .module('components.account')
  .run(appRun);

  appRun.$inject = ['routerHelper', 'AuthService'];
  /* @ngInject */
  function appRun(routerHelper, AuthService) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
    {
      state: 'index.account-detail',
      config: {
        url: 'account/:id',
        resolve: {
          auth: ['AuthService', function (AuthService) {
            return AuthService.checkAuth();
          },],
        },
        views: {
          'main@index': {
            template: '<account-detail></account-detail>',
          },
        },
        title: '_account.account.detail',
        ncyBreadcrumb: {
          label: '',
        },
        settings: {
          nav: 1,
        },
      },
    },
    ];
  }
})();
