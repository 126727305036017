(function () {
  'use strict';

  angular
    .module('layout')
    .controller('TopBarController', TopBarController);

  TopBarController.$inject = ['$state', 'AuthService'];
  /* @ngInject */
  function TopBarController($state, AuthService) {

    this.disconnect = function () {
      AuthService.disconnect();
    };

  }
})();
