
(function () {
  'use strict';

  angular
  .module('utils.toast')
  .factory('ToastService', ToastService);

  /**
  * Data Service
  * @type {Array}
  */
  ToastService.$inject = [
  '$q',
  '$state',
  '$mdToast',
  ];
  /* @ngInject */
  function ToastService($q, $state, $mdToast) {

    var service = {
      displayToast: displayToast,
    };

    return service;

    function displayToast(message) {
      $mdToast.show(
      $mdToast.simple()
        .textContent(message)
        .hideDelay(3000)
    );
    }

  }
})();
