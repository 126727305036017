(function () {
  'use strict';

  angular
  .module('components.stock')
  .factory('StockOrderService', StockOrderService);

  /**
  * Data Service
  * @type {Array}
  */
  StockOrderService.$inject = [
  '$q',
  '$state',
  ];
  /* @ngInject */
  function StockOrderService($q, $state) {

    var stockOrder  = initStockOrder();

    var service = {
      getStockOrder: getStockOrder,
      getTotal: getTotal,
      getSupplier: getSupplier,
      addProduct: addProduct,
      updateTotal: updateTotal,
      removeProduct: removeProduct,
      addSupplier: addSupplier,
      resetStockOrder: resetStockOrder,
      resetSupplier: resetSupplier,
    };

    return service;

    function initStockOrder() {
      var newStockOrder = {
        products: [],
        suppplier: {},
        total: {
          stockOrderSumIncVTA: 0,
          stockOrderSumExcVTA: 0,
          stockOrderSumVTA: 0,
          productCount: 0,
        },
      };
      return newStockOrder;
    };

    function resetStockOrder() {
      stockOrder = initStockOrder();
    }

    function resetSupplier() {
      stockOrder.suppplier = {};
    }

    function addProduct(product) {
      var productCopy = angular.copy(product);
      var productPosition = isInStockOrder(productCopy);

      if (productPosition === -1) {
        productCopy.quantity = 1;
        stockOrder.products.push(productCopy);
      } else {
        stockOrder.products[productPosition].quantity++;
      }

      updateTotal(stockOrder);
    }

    function addSupplier(suppplier) {
      var suppplierCopy = angular.copy(suppplier);
      angular.extend(stockOrder.suppplier, suppplierCopy);
    }

    function removeProduct(product) {
      for (var i = 0; i < stockOrder.products.length; i++) {
        if (stockOrder.products[i]._id === product._id) {
          stockOrder.products.splice(i, 1);
          updateTotal();
          break;
        }
      }
    }

    function updateTotal() {
      stockOrder.total.stockOrderSumExcVTA = 0;
      stockOrder.total.stockOrderSumIncVTA = 0;
      stockOrder.total.stockOrderSumVTA    = 0;
      stockOrder.total.productCount    = 0;

      for (var i = 0; i < stockOrder.products.length; i++) {
        stockOrder.total.stockOrderSumExcVTA += parseFloat(toFixed(stockOrder.products[i].sellingPriceExcVTA * stockOrder.products[i].quantity, 2));
        stockOrder.total.stockOrderSumIncVTA += parseFloat(toFixed(stockOrder.products[i].sellingPriceIncVTA * stockOrder.products[i].quantity, 2));
        stockOrder.total.productCount += stockOrder.products[i].quantity;
      }

      stockOrder.total.stockOrderSumVTA = stockOrder.total.stockOrderSumIncVTA - stockOrder.total.stockOrderSumExcVTA;
    }

    function getStockOrder() {
      return stockOrder;
    }

    function getTotal() {
      return stockOrder.total;
    }

    function getSupplier() {
      return stockOrder.suppplier;
    }


    /**
     * isInStockOrder
     * @note: in case element are created through a script remember that meteor doesn't use the ObjectId method of mongo
     */
    function isInStockOrder(product) {
      var alreadyInStockOrder = -1;
      if (stockOrder.products.length > 0) {
        for (var i = 0; i < stockOrder.products.length; i++) {
          if (stockOrder.products[i]._id === product._id) {
            alreadyInStockOrder = i;
            break;
          }
        }
      }

      return alreadyInStockOrder;
    }

    function toFixed(num, precision) {
      return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    }

  }
})();
