(function () {
  'use strict';

  angular
    .module('components.manufacturer')
    .controller('ManufacturerCreateController', ManufacturerCreateController);

  ManufacturerCreateController.$inject = [
    '$scope',
    'ManufacturerService',
    'ToastService',
  ];
  /* @ngInject */
  function ManufacturerCreateController(
    $scope,
    ManufacturerService,
    ToastService
    ) {
    
    var _this  = this;

    this.$onInit = function () {
      this.manufacturer = {};
    };

    this.createManufacturer = function (manufacturerForm, manufacturerData) {
      ManufacturerService.createManufacturer(manufacturerForm, manufacturerData)
      .then(function (result) {
        _this.manufacturer = {};
        ToastService.displayToast('Fabricant crée avec succès');
      });
    };
  }
})();
