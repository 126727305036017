(function () {
  "use strict";

  angular.module("components.account").run(appRun);

  appRun.$inject = ["routerHelper", "AuthService"];
  /* @ngInject */
  function appRun(routerHelper, AuthService) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
      {
        state: "index.accounts",
        config: {
          url: "accounts",
          allowedTo: ["super-admin"],
          views: {
            "main@index": {
              template: "<accounts></accounts>",
            },
          },
          title: "_account.accounts",
          ncyBreadcrumb: {
            label: "",
          },
          settings: {
            nav: 1,
          },
        },
      },
    ];
  }
})();
