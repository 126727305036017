(function () {
  'use strict';

  angular
    .module('components.account')
    .controller('AccountCreateController', AccountCreateController);

  AccountCreateController.$inject = [
    '$scope',
    '$timeout',
    '$reactive',
    'AccountService',
    'ToastService',
  ];
  /* @ngInject */
  function AccountCreateController(
    $scope,
    $timeout,
    $reactive,
    AccountService,
    ToastService
    ) {
    var _this = this;
    $reactive(this).attach($scope);
    this.subscribe('users');

    this.helpers({
      users: function () {
        return Meteor.users.find({});
      },
    });

    // test data
    this.user = {};

    this.createUser = function (userForm, userData) {
      AccountService.createUser(userForm, userData)
      .then(function (newUser) {
        _this.user = {};
        ToastService.displayToast('Utilisateur crée avec succès');
      });
    };
  }
})();
