(function () {
  'use strict'

  angular
  .module('components.order')
  .factory('OrderService', OrderService)

  OrderService.$inject = [
    '$q',
    '$state'
  ]
  /* @ngInject */
  function OrderService (
    $q,
    $state
    ) {
    var service = {
      createOrder: createOrder,
      getOrders: getOrders,
      getOrder: getOrder

    }

    return service

    function getOrders (queryParams) {
      return $q(function (resolve, reject) {
        console.log('getting orders')
        Meteor.call('getOrders', queryParams, function (error, result) {
          !error ? resolve(result) : reject(error)
        })
      })
    }

    function getOrder (orderId) {
      return $q(function (resolve, reject) {
        console.log('getting order ' + orderId)
        Meteor.call('getOrder', orderId, function (error, result) {
          !error ? resolve(result) : reject(error)
        })
      })
    }

    function createOrder (ticket) {
      return $q(function (resolve, reject) {
        console.log('saving ticket:', ticket)
        for (var i = 0; i < ticket.payment.means.length; i++) {
          delete ticket.payment.means[i].paymentType.$$mdSelectId
        }

        Meteor.call('createOrder', ticket, function (error, result) {
          console.log(error, result)
          !error ? resolve(result) : reject(error)
        })
      })
    }
  }
})()
