(function () {
  'use strict'

  angular
  .module('components.client')
  .factory('ClientService', ClientService)

  /**
  * Data Service
  * @type {Array}
  */
  ClientService.$inject = [
    '$q',
    '$state'
  ]
  /* @ngInject */
  function ClientService ($q, $state) {
    var service = {
      getClients: getClients,
      createClient: createClient,
      updateClient: updateClient,
      getOneClientById: getOneClientById,
      getOneClientByBarcode: getOneClientByBarcode,
      disableClient: disableClient,
      restoreClient: restoreClient
    }

    return service

    function getClients (disabled, fullName) {
      return $q(function (resolve, reject) {
        console.log('getting clients')
        Meteor.call('getClients', disabled, fullName, function (error, result) {
          !error ? resolve(result) : reject(error)
        })
      })
    }

    function createClient (clientForm, clientData) {
      console.log('creating', clientData)
      return $q(function (resolve, reject) {
        console.log('saving client:', clientData)
        Meteor.call('createClient', clientData, function (error, result) {
          console.log(error, result)
          !error ? resolve(result) : reject(error)
        })
      })
    }

    function updateClient (clientForm, clientData) {
      console.log(clientData)
      console.log(clientForm)
      return $q(function (resolve, reject) {
        Meteor.call('updateClient', clientData, function (error, result) {
          console.log(error, result)
          error ? reject('client update error') : resolve(result)
        })
      })
    }

    function disableClient (clientId) {
      return $q(function (resolve, reject) {
        console.log('Disabling client', clientId)
        Meteor.call('disableClient', clientId, function (error, result) {
          !error ? resolve(result) : reject(error)
        })
      })
    }

    function restoreClient (clientId) {
      return $q(function (resolve, reject) {
        console.log('restoring client', clientId)
        Meteor.call('restoreClient', clientId, function (error, result) {
          !error ? resolve(result) : reject(error)
        })
      })
    }

    function getOneClientByBarcode (barcode) {
      return $q(function (resolve, reject) {
        Meteor.call('getOneClientByBarcode', barcode, function (error, result) {
          !error ? resolve(result) : reject(error)
        })
      })
    }

    function getOneClientById (clientId) {
      return $q(function (resolve, reject) {
        Meteor.call('getOneClientById', clientId, function (error, result) {
          !error ? resolve(result) : reject(error)
        })
      })
    }
  }
})()
