(function () {
  'use strict';

  angular
  .module('components.manufacturer')
  .factory('ManufacturerService', ManufacturerService);

  /**
  * Data Service
  * @type {Array}
  */
  ManufacturerService.$inject = [
  '$q',
  '$state',
  ];
  /* @ngInject */
  function ManufacturerService($q, $state) {

    var service = {
      getManufacturers: getManufacturers,
      createManufacturer: createManufacturer,
      updateManufacturer: updateManufacturer,
      getOneManufacturerById: getOneManufacturerById,
    };

    return service;

    /**
     * Get all the manufacturers
     */

    function getManufacturers() {
      return $q(function (resolve, reject) {
        Meteor.call('getManufacturers', function (error, result) {
          resolve(result);
        });
      });
    }

    /**
    * Create Manufacturer
    */
    function createManufacturer(manufacturerForm, manufacturerData) {
      return $q(function (resolve, reject) {
        console.log('saving product:', manufacturerData);
        Meteor.call('createManufacturer', manufacturerData, function (error, result) {
          !error ? resolve(result) : reject(error);
        });
      });
    }

    /**
    * Update user
    */
    function updateManufacturer(manufacturerId, manufacturerForm, manufacturerData) {
      console.log(manufacturerData);
      Manufacturers.update(
        { _id: manufacturerId }, {
          $set: {
            name: manufacturerData.name,
            description: manufacturerData.description,
          },
        });
    }

    function getOneManufacturerById(manufacturerId) {
      return $q(function (resolve, reject) {
        console.log(manufacturerId);
        Meteor.call('getOneManufacturerById', manufacturerId, function (error, result) {
          console.log(error, result);
          resolve(result);
        });
      });

    }

  }
})();
