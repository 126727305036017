(function () {
  'use strict';

  angular
    .module('components.manufacturer')
    .controller('ManufacturerDetailController', ManufacturerDetailController);

  ManufacturerDetailController.$inject = [
    'ManufacturerService',
    '$scope',
    '$stateParams',
    '$reactive',
    '$mdDialog',
  ];
  /* @ngInject */
  function ManufacturerDetailController(
    ManufacturerService,
    $scope,
    $stateParams,
    $reactive,
    $mdDialog
    ) {
    $reactive(this).attach($scope);
    this.subscribe('manufacturers');

    this.helpers({
      manufacturer: function () {
        return Manufacturers.findOne({
          _id: $stateParams.id,
        });
      },
    });

    this.updateManufacturer = function (manufacturerForm, manufacturerData) {
      ManufacturerService.updateManufacturer($stateParams.id, manufacturerForm, manufacturerData);
    };

  }
})();
