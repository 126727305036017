(function () {
  'use strict';

  angular
  .module('components.manufacturer')
  .run(appRun);

  appRun.$inject = ['routerHelper', 'AuthService'];
  /* @ngInject */
  function appRun(routerHelper, AuthService) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
    {
      state: 'index.manufacturers',
      config: {
        url: 'manufacturers',
        allowedTo: ['super-admin'],
        resolve: {
          auth: [
            'AuthService', function (AuthService) {
              return AuthService.checkAuth();
            },
          ],
          permissions: [
            'AuthService', function (AuthService) {
              return AuthService.checkPermissions();
            },
          ],
        },
        views: {
          'main@index': {
            template: '<manufacturers></manufacturers>',

          },
        },
        title: '_account.manufacturers',
        ncyBreadcrumb: {
          label: '',
        },
        settings: {
          nav: 1,
        },
      },
    },
    ];
  }
})();
