angular.module('components.client')
  .config(mdDialogClientsConfig)
  .controller('ClientsPickerController', ClientsPickerController)

ClientsPickerController.$inject = [
  '$scope',
  '$mdDialog'
]
/* @ngInject */
function ClientsPickerController ($scope, $mdDialog) {
  $scope.cancel = function () {
    $mdDialog.cancel()
  }
}

mdDialogClientsConfig.$inject = ['$mdDialogProvider']
/* @ngInject */
function mdDialogClientsConfig ($mdDialogProvider) {
  $mdDialogProvider.addPreset('clientsPicker', {
    options: function () {
      return {
        clickOutsideToClose: true,
        controller: 'ClientsPickerController',
        template: '<md-dialog aria-label="List dialog">' +
        // '<md-subheader class="md-primary">Unread Messages</md-subheader>' +
        '<md-dialog-content>' +
        '<clients picker-mode="true"></clients>' +
        '</md-dialog-content>' +
        '<md-dialog-actions>' +
        '  <md-button ng-click="cancel()" class="md-primary action-btn">' +
        '<i class="material-icons">highlight_off</i>' +
        '    Fermer' +
        '  </md-button>' +
        '</md-dialog-actions>' +
        '</md-dialog>'
      }
    }
  })
}
