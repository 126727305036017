(function () {
  "use strict";

  angular.module("components.auth").factory("AuthService", AuthService);

  /**
   * Data Service
   * @type {Array}
   */
  AuthService.$inject = ["$q", "$state", "$auth", "$rootScope", "$timeout", "routerHelper"];
  /* @ngInject */
  function AuthService($q, $state, $auth, $rootScope, $timeout, routerHelper) {
    /*
      @Note:this should only return the connected user here
      and another publication should be called on the account controller
      otherwise all the availables user will be sent to the client
     */
    Meteor.subscribe("userData");

    var OK = false;

    var service = {
      disconnect: disconnect,
      // checkAuth: checkAuth,
      // checkPermissions: checkPermissions,
      validateConnection: validateConnection,
      validateA: validateA,
      OK: OK,
    };

    return service;

    /**
     * Disconnect
     * Disconnect the user from the meteor server and then in the front end
     */
    function disconnect() {
      Accounts.logout(function (err) {
        if (!err) {
          $state.go("index.login");
        } else {
          console.log("disconnection error : ", err);
        }
      });
    }

    function validateA() {
      return $q(function (resolve, reject) {
        validateConnection()
          .then(function () {
            $timeout(function () {
              service.OK = true;
              resolve();
            }, 10);
          })
          .catch(function (e) {
            service.OK = false;
            resolve();
          });
      });
    }

    function validateConnection() {
      return $auth.waitForUser().then(function (e) {
        $timeout(function () {
          if (Meteor.status().status === "connected" && Meteor.userId()) {
            return $q.resolve();
          } else {
            return $q.reject();
          }
        }, 10);
      });
    }

    // /**
    //  * Check Auth
    //  * resolver for routing
    //  */
    // function checkAuth() {
    //   if (Meteor.userId() === null) {
    //     return $q.reject("AUTH_REQUIREsD");
    //   } else {
    //     return $q.resolve();
    //   }
    // }

    // /**
    //  * Check permission
    //  * resolver for routing
    //  */
    // function checkPermissions() {
    //   console.log("check Permission");
    //   // console.log($state);
    //   return $auth.waitForUser().then(function () {
    //     // console.log(Meteor.status().status);
    //     // console.log(Meteor.user());
    //     // console.log(Meteor.userId());
    //     return $q.resolve(true);
    //     $timeout(function () {
    //       if (
    //         !$rootScope.toState.allowedTo ||
    //         _.intersection($rootScope.toState.allowedTo, Meteor.user().roles).length > 0
    //       ) {
    //       } else {
    //         return $q.reject("UNAUTHORIZED FROM permission");
    //       }
    //     }, 1000);
    //   });
    // }
  }
})();
