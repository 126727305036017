(function () {
  'use strict'

  angular
    .module('components.product')
    .controller('OrderDetailController', OrderDetailController)

  OrderDetailController.$inject = [
    'OrderService',
    '$scope',
    '$stateParams',
    '$reactive',
    '$mdDialog',
    'ToastService'
  ]
  /* @ngInject */
  function OrderDetailController (
    OrderService,
    $scope,
    $stateParams,
    $reactive,
    $mdDialog,
    ToastService
    ) {
    var _this = this

    this.$onInit = function () {
      this.getOrder($stateParams.id)
    }

    this.getOrder = function (orderId) {
      OrderService.getOrder(orderId)
      .then(function (result) {
        _this.order = result
      })
    }

    this.updateOrder = function (orderForm, productData) {
      OrderService.updateOrder($stateParams.id, orderForm)
      ToastService.displayToast('Commande mise à jour avec succès')
    }
  }
})()
