(function () {
  'use strict';

  angular
    .module('app')
    .controller('AppController', AppController);

  AppController.$inject = [
    '$rootScope',
    '$timeout',
  ];
  /* @ngInject */
  function AppController(
    $rootScope,
    $timeout
    ) {
    $rootScope.hideSplash = false;

    this.$onInit = function () {
      hideSplash();
    };

    function hideSplash() {
      // Force a 1 second delay so we can see the splash.
      $timeout(function () {
          $rootScope.hideSplash = true;
        }, 200);
    }
  }
})();
