(function () {
  'use strict';

  angular
  .module('components.account')
  .component('passwordManager', {
    templateUrl: 'app/components/account/password-manager/templates/password-manager.html',
    controller: 'PasswordManagerController',
    bindings: {},
  });

})();
