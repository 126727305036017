(function () {
  'use strict';

  angular
  .module('components.manufacturer')
  .run(appRun);

  appRun.$inject = ['routerHelper', 'AuthService'];
  /* @ngInject */
  function appRun(routerHelper, AuthService) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
    {
      state: 'index.manufacturer-detail',
      config: {
        url: 'manufacturer/:id',
        resolve: {
          auth: ['AuthService', function (AuthService) {
            return AuthService.checkAuth();
          }, ],
        },
        views: {
          'main@index': {
            template: '<manufacturer-detail></manufacturer-detail>',
          },
        },
        title: '_manufacturer.detail',
        ncyBreadcrumb: {
          label: '',
        },
        settings: {
          nav: 1,
        },
      },
    },
    ];
  }
})();
