
(function () {
  'use strict';

  angular
    .module('components.stock')
    .controller('StockOrderController', StockOrderController);

  StockOrderController.$inject = [
    '$scope',
    'ProductService',
    'StockOrderService',
    '$timeout',
    '$mdDialog',

  ];
  /* @ngInject */
  function StockOrderController(
    $scope,
    ProductService,
    StockOrderService,
    $timeout,
    $mdDialog

    ) {

    console.log('stock order controller');

    var _this   = this;

    this.initStockOrder = function () {
      _this.stockOrder = StockOrderService.getStockOrder();
      _this.total  = StockOrderService.getTotal();
      _this.supplier = StockOrderService.getSupplier();
    };

    this.$onInit = function () {
      _this.initStockOrder();
    };

    $scope.$on('productPicker::complete', function (event, args) {
      ProductService.getOneProductById(args.productId)
      .then(function (result) {
        if (result) {
          StockOrderService.addProduct(result);
        }
      });
    });

    this.openProductsPicker = function (ev) {
      $mdDialog.show($mdDialog.productsPicker());
    };

    this.openSuppliersPicker = function (ev) {
      $mdDialog.show($mdDialog.clientsPicker());
    };

    this.resetStockOrder = function () {
      StockOrderService.resetStockOrder();
      _this.initStockOrder();
    };

    this.isSupplierEmpty = function () {
      return Object.keys(_this.supplier).length === 0;
    };

  }
})();
