(function () {
  'use strict';

  angular
    .module('components.account')
    .controller('AccountsController', AccountsController);

  AccountsController.$inject = [
    '$scope',
    '$timeout',
    '$reactive',
    'AccountService',
  ];
  /* @ngInject */
  function AccountsController(
    $scope,
    $timeout,
    $reactive,
    AccountService
    ) {
    $reactive(this).attach($scope);
    this.subscribe('users');

    this.helpers({
      users: function () {
        return Meteor.users.find({});
      },
    });
  }
})();
