(function () {
  'use strict';

  angular
  .module('components.stock')
  .run(appRun);

  appRun.$inject = ['routerHelper', 'AuthService'];
  /* @ngInject */
  function appRun(routerHelper, AuthService) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
    {
      state: 'index.stock-order',
      config: {
        url: 'stock-order',
        allowedTo: ['super-admin', 'user'],
        resolve: {
          auth: ['AuthService', function (AuthService) {
            return AuthService.checkAuth();
          },],

          permissions: ['AuthService', function (AuthService) {
              return AuthService.checkPermissions();
            },],
        },
        views: {
          'main@index': {
            template: '<stock-order></stock-order>',
          },
        },
        title: '_Stock.order',
        ncyBreadcrumb: {
          label: '',
        },
        settings: {
          nav: 1,
        },
      },
    },
    ];
  }
})();
