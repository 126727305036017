angular
  .module('components.auth')
  .controller('LoginController', LoginController);

LoginController.$inject = ['$scope', '$state'];
/* @ngInject */
function LoginController($scope, $state) {
  this.user = 'user';
  this.password = '1234';
  this.loginWithPassword = function () {
    Meteor.loginWithPassword(this.user, this.password, function (error) {
      if (!error) {
        $state.go('index');
      } else {
        console.log(error);
      }
    });
  };
}
