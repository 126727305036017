(function () {
  'use strict';

  angular
    .module('components.stock')
    .controller('StockOrderListController', StockOrderListController);

  StockOrderListController.$inject = [
    '$scope',
    'ProductService',
    'StockOrderService',
  ];
  /* @ngInject */
  function StockOrderListController(
   $scope,
   ProductService,
   StockOrderService
  ) {
    var _this = this;

    this.updateQuantity = function (item) {
      if (item.quantity === 0) {
        console.log('removing',item)
        StockOrderService.removeProduct(item);
      } else if (!item.quantity) {
        item.quantity = 1;
      } else {
        document.activeElement.blur();
        StockOrderService.updateTotal();
      }
    };

    this.removeProduct = function (item) {
      StockOrderService.removeProduct(item);

    };

  }
})();
