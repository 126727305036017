(function () {
  'use strict';

  angular
  .module('components.barcode')
  .factory('BarcodeScannerService', BarcodeScannerService);

  BarcodeScannerService.$inject = ['$rootScope'];
  /* @ngInject */
  function BarcodeScannerService($rootScope) {
    var service = {
      initialize: initialize,
    };
    return service;

    function initialize() {
      var isScanning = false;
      var barcode = [];
      var lastKeyPressTimeStamp = 0;
      var keypressHandler = function keypressHandler(e) {

        var currentTimestamp = e.timeStamp;

        var char = String.fromCharCode(e.which);

        if (e.key == 'Enter') {
          var scannedResult = barcode.join('').slice(-13);
          $rootScope.$broadcast('barcodeScanner::complete', { barcode: scannedResult });
          barcode.length = 0;
        } else {
          if (currentTimestamp - lastKeyPressTimeStamp > 3000) {
            barcode.length = 0;
          }
          var digits = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

          if (digits.indexOf(parseInt(char)) !== -1) {
            barcode.push(char);
          }

        }

        lastKeyPressTimeStamp = currentTimestamp;
      };

      document.addEventListener('keypress', keypressHandler);
    }

  }
})();
