var paymentMean = {
  templateUrl: 'app/components/payment/payment-mean/payment-mean.html',
  controller: 'PaymentMeanController',
  bindings: {
    ticket: '<',
    payment: '='
  }
};
angular.module('components.payment')
  .component('paymentMean', paymentMean);
