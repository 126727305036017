(function () {
  'use strict';

  angular
  .module('layout')
  .run(appRun);

  appRun.$inject = ['routerHelper'];
  /* @ngInject */
  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
    {
      state: 'index',
      config: {
        url: '/',
        views: {
          '@': {
            template: '<layout class="layout-container" layout="row" flex></layout>',
          },
          'top-bar@index': { template: '<top-bar></top-bar>' },
          'left-menu@index': { template: '<left-menu></left-menu>' },
          'main@index': { template: '<main></main>' },
        },
        title: 'root',
        ncyBreadcrumb: {
          label: '',
        },
        settings: {
          nav: 1,
        },
      },
    },
    ];
  }
})();
