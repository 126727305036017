(function () {
  'use strict'

  angular
    .module('components.client')
    .controller('ClientDetailController', ClientDetailController)

  ClientDetailController.$inject = [
    'ClientService',
    'OrderService',
    '$scope',
    '$stateParams',
    '$reactive',
    '$mdDialog',
    'ToastService'
  ]
  /* @ngInject */
  function ClientDetailController (
    ClientService,
    OrderService,
    $scope,
    $stateParams,
    $reactive,
    $mdDialog,
    ToastService
    ) {
    var _this = this

    this.$onInit = function () {
      this.getClient($stateParams.id)
      getOrdersByClient($stateParams.id)
    }

    this.getClient = function (clientId) {
      ClientService.getOneClientById(clientId)
      .then(function (result) {
        _this.client = result
      })
    }

    this.updateClient = function (clientForm, clientData) {
      ClientService.updateClient(clientForm, clientData)
      ToastService.displayToast('Client mis à jour avec succès')
    }

    function getOrdersByClient (clientId) {
      var queryParams = {
        clientId: clientId
      }
      console.log(queryParams)
      OrderService.getOrders(queryParams)
        .then(function (result) {
          console.log('result')
          console.log(result)
          _this.orders = result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
})()
