(function () {
  "use strict";

  angular.module("components.order").run(appRun);

  appRun.$inject = ["routerHelper", "AuthService"];
  /* @ngInject */
  function appRun(routerHelper, AuthService) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
      {
        state: "index.orders",
        config: {
          url: "orders",
          allowedTo: ["super-admin", "user"],
          views: {
            "main@index": {
              template: "<orders></orders>",
            },
          },
          title: "_account.orders",
          ncyBreadcrumb: {
            label: "",
          },
          settings: {
            nav: 1,
          },
        },
      },
    ];
  }
})();
