(function () {
  'use strict';

  angular
    .module('components.account')
    .controller('PasswordManagerController', PasswordManagerController);

  PasswordManagerController.$inject = [
    'AccountService',
    '$scope',
    '$stateParams',
    '$reactive',
    '$mdDialog',
  ];
  /* @ngInject */
  function PasswordManagerController(
    AccountService,
    $scope,
    $stateParams,
    $reactive,
    $mdDialog
    ) {

    /**
    * Dialog box for changing the
    */
    this.openDialog = function (ev) {
      $mdDialog.show({
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: { parent: $scope },
        controller: angular.noop,
        controllerAs: 'dialogCtrl',
        bindToController: true,
        templateUrl: 'app/components/account/password-manager/templates/password-manager.dialog.html',
      });
    };

    this.updatePassword = function (password) {
      AccountService.updatePassword(password)
      .then(function (result) {
        $mdDialog.hide();
      }, function (error) {

        console.log(error);
        $mdDialog.hide();
      });
    };

    this.cancelDialog = function () {
      $mdDialog.cancel();
    };

  }
})();
