(function () {
  'use strict'

  angular
    .module('components.client')
    .controller('ClientCreateController', ClientCreateController)

  ClientCreateController.$inject = [
    '$scope',
    'ClientService',
    'ToastService'
  ]
  /* @ngInject */
  function ClientCreateController (
    $scope,
    ClientService,
    ToastService
    ) {
    var _this = this

    this.$onInit = function () {
      this.client = {}
    }

    this.createClient = function (clientForm, clientData) {
      ClientService.createClient(clientForm, clientData)
      .then(function (result) {
        _this.client = {}
        ToastService.displayToast('Client crée avec succès')
      })
    }
  }
})()
