(function () {
  'use strict';

  angular
    .module('components.payment')
    .controller('PaymentSelectorController', PaymentSelectorController);

  PaymentSelectorController.$inject = [
    '$scope',
    '$stateParams',
    'TicketService',
  ];
  /* @ngInject */
  function PaymentSelectorController(
    $scope,
    $stateParams,
    TicketService
    ) {

    var _this = this;
    this.addNewPayment = function () {
      _this.ticket.payment.means.push({ paymentType: {}, value: 0 });

      // When the first payment mean is added, prefill the input with the total left to pay
      // since it's going to be the most used case
      if (_this.ticket.payment.means.length === 1) {
        _this.ticket.payment.means[0].value = _this.ticket.payment.leftToPay;
        TicketService.leftToPay();
      }
    };

    $scope.$on('payment::delete', function (e, payment) {
      deletePayment(payment);
      TicketService.leftToPay();
    });

    function deletePayment(payment) {
      for (var i = 0; i < _this.ticket.payment.means.length; i++) {
        if (_this.ticket.payment.means[i] == payment) {
          _this.ticket.payment.means.splice(i, 1);
          break;
        }
      }
    }

    $scope.$on('payment::update', function (e) {
      console.log('update');
      if (_this.ticket.products.length < 1) {
        console.log('no products nothig to process');
        return;
      }

      TicketService.leftToPay();

    });

    function toFixed(num, precision) {
      return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    }

  }
})();
