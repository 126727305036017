(function () {
  'use strict';

  angular
    .module('components.account')
    .controller('AccountDetailController', AccountDetailController);

  AccountDetailController.$inject = [
    'AccountService',
    '$scope',
    '$stateParams',
    '$reactive',
    '$mdDialog',
    'ToastService',
  ];
  /* @ngInject */
  function AccountDetailController(
    AccountService,
    $scope,
    $stateParams,
    $reactive,
    $mdDialog,
    ToastService
    ) {
    $reactive(this).attach($scope);
    this.subscribe('users');

    this.helpers({
      user: function () {
        return Meteor.users.findOne({
          _id: $stateParams.id,
        });
      },
    });

    this.updateUser = function (userForm, userData) {
      AccountService.updateUser(userData)
      .then(function () {
        ToastService.displayToast('Utilisateur mis à jour avec succès');
      })
      .catch(function (error) {
        console.log(error);
        var message = 'Erreur lors de la mise à jour de l\'utilisateur';
        switch(error.reason) {
          case 'Username already exists.' : 
            message = "Nom d'utilisateur déja utilisé";
          break;
          case 'email address is already registered' : 
            message = "Email déja utilisé";
          break;
        }
        ToastService.displayToast(message);
      });
    };

  }
})();
